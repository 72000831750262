import { v4 } from 'uuid';

export const settings = {
  weblink: "https://volodyakurashov.com/"
}

export const data = {
  "001": [
    {
      name: "001_ШАПКА",
      path: "/001_ШАПКА.jpeg",
      english: false,
      sort: 0,
      id: v4(),
    },
  ],
  "002": [
    {
      name: "002_ШАПКА_МЦ_1",
      path: "/002_ШАПКА_МЦ_1.jpeg",
      english: "/ENGLISH_VER/002_eng_ШАПКА_МЦ_1.jpeg",
      sort: 3,
      id: v4(),
    },
  ],
  "002-01": [
    {
      name: "002_ШАПКА_МЦ_2",
      path: "/002_ШАПКА_МЦ_2.jpeg",
      english: false,
      sort: 4,
      id: v4(),
    },
  ],
  "002-02-t": [
    {
      name: "002_ШАПКА_ТЕКСТ",
      desktop: {
        russian: "/text_ru_full_tr.svg",
        english: "/text_eng_full_tr.svg",
      },
      mobile: {
        russian: "/text_ru_mob_tr.svg",
        english: "/text_eng_mob_tr.svg",
      },      
      sort: 5,
      id: v4(),
    },
  ],
  "002-03": [
    {
      name: "002_ШАПКА_УГС",
      path: "/002_ШАПКА_УГС.jpeg",
      english: false,
      sort: 6,
      id: v4(),

    },
  ],
  "003-h-b": [
    {
      name: "003_УГС_",
      path: "/003_УГС_.jpeg",
      english: "/ENGLISH_VER/003_eng_УГС_.jpeg",
      sort: 8,
      id: v4(),

    },
    {
      name: "003_УГС_00",
      path: "/003_УГС_00.jpeg",
      english: false,
      sort: 9,
      id: v4(),

    },
    {
      name: "003_УГС_01",
      path: "/003_УГС_01.jpeg",
      english: false,
      sort: 10,
      id: v4(),

    },
    {
      name: "003_УГС_02",
      path: "/003_УГС_02.jpeg",
      english: false,
      sort: 11,
      id: v4(),

    },
    {
      name: "003_УГС_03",
      path: "/003_УГС_03.jpeg",
      english: false,
      sort: 12,
      id: v4(),

    },
    {
      name: "003_УГС_04",
      path: "/003_УГС_04.jpeg",
      english: false,
      sort: 13,
    },
    {
      name: "003_УГС_05",
      path: "/003_УГС_05.jpeg",
      english: false,
      sort: 14,
      id: v4(),

    },
  ],
  "004": [
    {
      name: "004_ТЕЛО",
      path: "/004_ТЕЛО.jpeg",
      english: false,
      sort: 15,
      id: v4(),

    },
  ],
  "005-h-b": [
    {
      name: "005_ТЕЛО_",
      path: "/005_ТЕЛО_.jpeg",
      english: "/ENGLISH_VER/005_eng_ТЕЛО_.jpeg",
      sort: 17,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_00",
      path: "/005_ТЕЛО_00.jpeg",
      english: false,
      sort: 18,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_01",
      path: "/005_ТЕЛО_01.jpeg",
      english: false,
      sort: 19,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_02",
      path: "/005_ТЕЛО_02.jpeg",
      english: false,
      sort: 20,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_03",
      path: "/005_ТЕЛО_03.jpeg",
      english: false,
      sort: 21,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_04",
      path: "/005_ТЕЛО_04.jpeg",
      english: false,
      sort: 22,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_05",
      path: "/005_ТЕЛО_05.jpeg",
      english: false,
      sort: 23,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_06",
      path: "/005_ТЕЛО_06.jpeg",
      english: false,
      sort: 24,
      id: v4(),

    },
    {
      name: "005_ТЕЛО_ЗАБИВКА",
      path: "/005_ТЕЛО_ЗАБИВКА.jpeg",
      english: false,
      sort: 25,
      id: v4(),

    },
  ],
  "006": [
    {
      name: "006_ПРЕОДОЛЕНИЕ",
      path: "/006_ПРЕОДОЛЕНИЕ.jpeg",
      english: false,
      sort: 26,
      id: v4(),

    },
  ],
  "007-h-b": [
    {
      name: "007_ПРЕОДОЛЕНИЕ_",
      path: "/007_ПРЕОДОЛЕНИЕ_.jpeg",
      english: "/ENGLISH_VER/007_eng_ПРЕОДОЛЕНИЕ_.jpeg",
      sort: 28,
      id: v4(),

    },
    {
      name: "007_ПРЕОДОЛЕНИЕ_00",
      path: "/007_ПРЕОДОЛЕНИЕ_00.jpeg",
      english: false,
      sort: 29,
      id: v4(),

    },
    {
      name: "007_ПРЕОДОЛЕНИЕ_01",
      path: "/007_ПРЕОДОЛЕНИЕ_01.jpeg",
      english: false,
      sort: 30,
      id: v4(),

    },
    {
      name: "007_ПРЕОДОЛЕНИЕ_02",
      path: "/007_ПРЕОДОЛЕНИЕ_02.jpeg",
      english: false,
      sort: 31,
      id: v4(),

    },
    {
      name: "007_ПРЕОДОЛЕНИЕ_03",
      path: "/007_ПРЕОДОЛЕНИЕ_03.jpeg",
      english: false,
      sort: 32,
    },
  ],
  "008": [
    {
      name: "008_ИССТУПЛЕНИЕ",
      path: "/008_ИССТУПЛЕНИЕ.jpeg",
      english: false,
      sort: 33,
      id: v4(),

    },
  ],
  "009-h-b": [
    {
      name: "009_ИССТУПЛЕНИЕ_",
      path: "/009_ИССТУПЛЕНИЕ_.jpeg",
      english: "/ENGLISH_VER/009_eng_ИССТУПЛЕНИЕ_.jpeg",
      sort: 35,
      id: v4(),

    },
    {
      name: "009_ИССТУПЛЕНИЕ_00",
      path: "/009_ИССТУПЛЕНИЕ_00.jpeg",
      english: false,
      sort: 36,
      id: v4(),

    },
    {
      name: "009_ИССТУПЛЕНИЕ_01",
      path: "/009_ИССТУПЛЕНИЕ_01.jpeg",
      english: false,
      sort: 37,
      id: v4(),

    },
    {
      name: "009_ИССТУПЛЕНИЕ_02",
      path: "/009_ИССТУПЛЕНИЕ_02.jpeg",
      english: false,
      sort: 38,
      id: v4(),

    },
    {
      name: "009_ИССТУПЛЕНИЕ_03",
      path: "/009_ИССТУПЛЕНИЕ_03.jpeg",
      english: false,
      sort: 39,
      id: v4(),

    },
    {
      name: "009_ИССТУПЛЕНИЕ_04",
      path: "/009_ИССТУПЛЕНИЕ_04.jpeg",
      english: false,
      sort: 40,
      id: v4(),

    },
    {
      name: "009_ИССТУПЛЕНИЕ_05",
      path: "/009_ИССТУПЛЕНИЕ_05.jpeg",
      english: false,
      sort: 41,
      id: v4(),

    },
  ],
  "010": [
    {
      name: "010_ВДОХНОВЕНИЕ",
      path: "/010_ВДОХНОВЕНИЕ.jpeg",
      english: false,
      sort: 42,
      id: v4(),

    },
  ],
  "011": [
    {
      name: "011_ВДОХНОВЕНИЕ_",
      path: "/011_ВДОХНОВЕНИЕ_.jpeg",
      english: "/ENGLISH_VER/011_eng_ВДОХНОВЕНИЕ_.jpeg",
      sort: 44,
      id: v4(),

    },
  ],
  "012-s": [
    {
      name: "012_ВДОХНОВЕНИЕ_01",
      path: "/012_ВДОХНОВЕНИЕ_01.jpeg",
      isSlideShow: "true",
      english: false,
      sort: 45,
      id: v4(),

    },
    {
      name: "012_ВДОХНОВЕНИЕ_02",
      path: "/012_ВДОХНОВЕНИЕ_02.jpeg",
      isSlideShow: "true",
      english: false,
      sort: 46,
      id: v4(),

    },
  ],
  "013": [
    {
      name: "013_ПРЕОБРАЖЕНИЕ",
      path: "/013_ПРЕОБРАЖЕНИЕ.jpeg",
      english: false,
      sort: 47,
      id: v4(),

    },
  ],
  "014-h-b": [
    {
      name: "014_ПРЕОБРАЖЕНИЕ_",
      path: "/014_ПРЕОБРАЖЕНИЕ_.jpeg",
      english: "/ENGLISH_VER/014_eng_ПРЕОБРАЖЕНИЕ_.jpeg",
      sort: 49,
      id: v4(),

    },
    {
      name: "014_ПРЕОБРАЖЕНИЕ_00",
      path: "/014_ПРЕОБРАЖЕНИЕ_00.jpeg",
      english: false,
      sort: 50,
      id: v4(),
    },
    {
      name: "014_ПРЕОБРАЖЕНИЕ_01",
      path: "/014_ПРЕОБРАЖЕНИЕ_01.jpeg",
      english: false,
      sort: 51,
      id: v4(),

    },
    {
      name: "014_ПРЕОБРАЖЕНИЕ_02",
      path: "/014_ПРЕОБРАЖЕНИЕ_02.jpeg",
      english: false,
      sort: 52,
      id: v4(),

    },
    {
      name: "014_ПРЕОБРАЖЕНИЕ_03",
      path: "/014_ПРЕОБРАЖЕНИЕ_03.jpeg",
      english: false,
      sort: 53,
      id: v4(),

    },
  ],
  "015": [
    {
      name: "015_ПРОБУЖДЕНИЕ",
      path: "/015_ПРОБУЖДЕНИЕ.jpeg",
      english: false,
      sort: 54,
      id: v4(),

    },
  ],
  "016-h-b": [
    {
      name: "016_ПРОБУЖДЕНИЕ_",
      path: "/016_ПРОБУЖДЕНИЕ_.jpeg",
      english: "/ENGLISH_VER/016_eng_ПРОБУЖДЕНИЕ_.jpeg",
      sort: 56,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_00",
      path: "/016_ПРОБУЖДЕНИЕ_00.jpeg",
      english: false,
      sort: 57,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_01",
      path: "/016_ПРОБУЖДЕНИЕ_01.jpeg",
      english: false,
      sort: 58,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_02",
      path: "/016_ПРОБУЖДЕНИЕ_02.jpeg",
      english: false,
      sort: 59,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_03",
      path: "/016_ПРОБУЖДЕНИЕ_03.jpeg",
      english: false,
      sort: 60,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_04",
      path: "/016_ПРОБУЖДЕНИЕ_04.jpeg",
      english: false,
      sort: 61,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_05",
      path: "/016_ПРОБУЖДЕНИЕ_05.jpeg",
      english: false,
      sort: 62,
      id: v4(),

    },
    {
      name: "016_ПРОБУЖДЕНИЕ_06",
      path: "/016_ПРОБУЖДЕНИЕ_06.jpeg",
      english: false,
      sort: 63,
      id: v4(),

    },
  ],
  "017": [
    {
      name: "017_ПРОБУЖДЕНИЕ_ЗАБИВКА",
      path: "/017_ПРОБУЖДЕНИЕ_ЗАБИВКА.jpeg",
      english: false,
      sort: 64,
      id: v4(),

    },
  ],
  "018": [
    {
      name: "018_КНИГА",
      path: "/018_КНИГА.jpeg",
      english: "/ENGLISH_VER/018_eng_КНИГА.jpeg",
      sort: 100,
      id: v4(),

    },
  ],
  "019-h-b": [
    {
      name: "001",
      path: "/018_book%20photos/001.jpeg",
      english: false,
      sort: 65,
    },
    {
      name: "002",
      path: "/018_book%20photos/002.jpeg",
      english: false,
      sort: 66,
    },
    {
      name: "003",
      path: "/018_book photos/003.jpeg",
      english: false,
      sort: 67,
    },
    {
      name: "004",
      path: "/018_book%20photos/004.jpeg",
      english: false,
      sort: 68,
    },
    {
      name: "005",
      path: "/018_book photos/005.jpeg",
      english: false,
      sort: 69,
    },
    {
      name: "006",
      path: "/018_book%20photos/006.jpeg",
      english: false,
      sort: 70,
    },
    {
      name: "007",
      path: "/018_book%20photos/007.jpeg",
      english: false,
      sort: 71,
    },
    {
      name: "008",
      path: "/018_book%20photos/008.jpeg",
      english: false,
      sort: 72,
    },
    {
      name: "009",
      path: "/018_book%20photos/009.jpeg",
      english: false,
      sort: 73,
    },
    {
      name: "010",
      path: "/018_book%20photos/010.jpeg",
      english: false,
      sort: 74,
    },
    {
      name: "011",
      path: "/018_book%20photos/011.jpeg",
      english: false,
      sort: 75,
    },
    {
      name: "012",
      path: "/018_book%20photos/012.jpeg",
      english: false,
      sort: 76,
    },
    {
      name: "013",
      path: "/018_book%20photos/013.jpeg",
      english: false,
      sort: 77,
    },
    {
      name: "014",
      path: "/018_book%20photos/014.jpeg",
      english: false,
      sort: 78,
    },
    {
      name: "015",
      path: "/018_book%20photos/015.jpeg",
      english: false,
      sort: 79,
    },
    {
      name: "016",
      path: "/018_book%20photos/016.jpeg",
      english: false,
      sort: 80,
    },
    {
      name: "017",
      path: "/018_book%20photos/017.jpeg",
      english: false,
      sort: 81,
    },
    {
      name: "018",
      path: "/018_book%20photos/018.jpeg",
      english: false,
      sort: 82,
    },
    {
      name: "019",
      path: "/018_book%20photos/019.jpeg",
      english: false,
      sort: 83,
    },
  ],
  "020-m": [
    {
      name: "END",
      path: "/00_END.jpg", 
      english: false,
      sort: 86,
      id: v4(),

    }],
  "021-l": [
    {
      // name: "019_ФИНИШ_ЛОГО",
      // path: "/019_ФИНИШ_ЛОГО.jpeg",
      // english: false,
      sort: 87,
      id: v4(),

    },
  ],
};

 