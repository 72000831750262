import React, { useContext, useState } from "react";

const AppContext = React.createContext(null);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = (props) => {
  const { value } = props;
  const [settings, setSettings] = useState(value);

  const changeLanguage = (settings) => {
    const language = settings.language === "english" ? "russian" : "english";
    setSettings({ ...settings, language });
    console.log(settings);

  };

  const setMenuShow = (settings, trigger) => {
    let showMenu;
    if (trigger) {
      showMenu = true;
    } else {
      showMenu = !settings.showMenu;
    }
    console.log(showMenu, trigger)
    setSettings({ ...settings, showMenu });
  };



  return (
    <AppContext.Provider
      value={{ settings, changeLanguage, setMenuShow }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
