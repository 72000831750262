import "./input.css";
import React from "react";
import {data, settings} from "./content/data";
import Controller from "./components/controller";

function App() {
  const dataArray = Object.entries(data);
  return <Controller data={dataArray} settings={settings}/>;
}

export default App;
