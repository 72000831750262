import React from "react";
import { useAppContext } from "./context.hooks";
import Logo from "../content/logo.svg";
import Watermark_Rus from "../content/watermark_rus.svg";
import Watermark_Eng from "../content/watermark_eng.svg";

export const Contact = () => {
  const { settings } = useAppContext();
  const signature = settings.language === "english" ?  Watermark_Eng  :  Watermark_Rus ;
  return (
    <>
      <div className="w-screen h-screen grid grid-rows-1 grid-cols-1 bg-white">
        <div className="row-start-1 row-end-2 col-start-1 col-end-2 flex">
          <img alt="" src={Logo} className="sm:w-2/6 m:w-4/6 w-3/4 m-auto self-center "></img></div>
        <div className="row-start-1 row-end-2 col-start-1 col-end-2 flex justify-center">
          <a href={settings.weblink} className="sm:w-2/5 w-4/5 m-0 self-end "> <img alt=""  src={signature}/></a>
        </div>
      </div>
    </>
  );
};
