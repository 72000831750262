import React from "react";
import { useInView } from "react-intersection-observer";
import ImageContent from './image.content';

const ContainerSlideShow = (props) => {
    const { ref, inView } = useInView({
        threshold: 0,
      });
 
      const fadeEffect = (index) => index === 1 ? "animate-fading" : "";
    
      return (
        <div
          ref={ref}
          data-id={props.data[0].id}
          className={`w-screen grid grid-cols-1`}
        >
        {props.data.map((el,index) => (
        <div
          className={`col-start-1 row-start-1 flex-none w-full self-center ${fadeEffect(index)}`}
        >
          <div
            className={`snap-center pointer-events-none`}
          >
            <ImageContent
              DPR={props.DPR}
              data-id={props.id}
              inView={inView}
              {...el}
              key={"slideShow" + el.name}
            />
          </div>{" "}
        </div>
      ))}
        </div>
      );
}

export default ContainerSlideShow;
