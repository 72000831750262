import React from "react";
import { useAppContext } from "./context.hooks";

import M from "../content/M.svg";

const Navigation = ({ children }) => {
  const { settings, setMenuShow} = useAppContext();
  const ButtonImage = M;

  return (
    <div className="relevant h-screen w-screen ">
      <div
        onClick={() => setMenuShow(settings)}
        className={`fixed top-6 right-6 z-20 h-10 md:h-14`}
      >
        <img src={ButtonImage} className="h-full animate-rotateOut" alt="" />
      </div>
      {children}
    </div>
  );
};

export default Navigation;
