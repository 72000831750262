import React, { useEffect, useState } from "react";
import Right from "../content/right-opt.svg";
import RightEnglish from "../content/right-opt_eng.svg";
import {useAppContext} from "./context.hooks";

const ImageButtons = (props) => {
  const {settings} = useAppContext();
  const RightButton = settings.language === "english" ? RightEnglish : Right;
  const [animate, setAnimate] = useState("");
  useEffect(() => {
    props.inView && props.showArrow
      ? setAnimate("animate-bounceRightLeft")
      : setAnimate("");
  }, [props.inView, props.showArrow]);

  const isRightButtonEnable =
    props.lastImage > 0 && props.lastImage !== props.index;
  const isLeftButtonEnable = props.lastImage > 0 && props.index !== 0;

  const scrollLeft = () => {
    const element = document.querySelector(`[data-id="${props.dataId}"]`);
    element.scrollBy({
      left: -window.innerWidth,
      behavior: "smooth",
    });
  };

  const showArrow = props.index === 0 && props.showArrow;

  const scrollRight = () => {
    const element = document.querySelector(`[data-id="${props.dataId}"]`);
    element.scrollBy({
      left: window.innerWidth,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isLeftButtonEnable && (
        <div
          onClick={scrollLeft}
          className={`col-start-1 col-end-2 cursor-w-resize row-start-1 row-end-2`}
        ></div>
      )}

      {props.children}

      {isRightButtonEnable && (
        <div
          onClick={scrollRight}
          className={`col-start-2 col-end-3 cursor-e-resize row-start-1 row-end-2 flex justify-end items-center z-10`}
        ></div>
      )}

      {showArrow && (
        <div
          onClick={scrollRight}
          className={`col-start-2 cursor-e-resize col-end-3 row-start-1 row-end-3 flex items-end	z-0 `}
        >
          <img alt="" src={RightButton} className={`mb-0 ${animate}  `} />
        </div>
      )}
    </>
  );
};

export default ImageButtons;
