import React from "react";
import { useInView } from "react-intersection-observer";
import ImageButtons from "./image.buttons";
import ImageContent from "./image.content";

const ContainerRow = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  
  const dataId = props.id;
  return (
    <div
      ref={ref}
      data-id={dataId}
      className={`w-screen  h-auto snap-x overflow-x-auto overflow-y-hidden flex-row flex snap-always snap-center bg-white`}
    >
      {props.data.map((el, index) => (
        <div
        data-id={el.id}
          className={`grid grid-cols-2 grid-rows-1 w-full self-center flex-none h-full`}
        >
          <ImageButtons
            showArrow={props.showArrow}
            index={index}
            lastImage={props.data.length - 1}
            dataId={dataId}
            inView={inView}
            key={"containerRowButtons" + el.name}
          >
            <div
              className={`snap-center pointer-events-none col-start-1 col-end-3 row-start-1 row-end-2`}
            >
              <ImageContent
                DPR={props.DPR}
                inView={inView}
                {...el}
                key={"containerRowImage" + el.name}
              />
            </div>
          </ImageButtons>
        </div>
      ))}
    </div>
  );
};

export default ContainerRow;
