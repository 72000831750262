import ContainerRow from "./container.row";
import ContainerSlideShow from "./container.slideshow";
import React, { useState } from "react";
import ContainerColumn from "./container.column";
import { AppContextProvider } from "./context.hooks";
import Navigation from "./navigation";
import useWindowDimensions from './useWindowDimension.hook';
import { Contact } from './contact';
import Menu from "./menu";


const language = /^ru\b/.test(navigator.language) ? "russian" : "english";

function Controller(props) {
  const {  data } = props;
  const { width, DPR } = useWindowDimensions();
  const [settings] = useState({
    language: language,
    width: width,
    DPR: DPR,
    lines: [],
    position: null,
    showMenu: false, 
    weblink: props.settings.weblink
  });
  const componentSelector = (el) => {
    const keys = {
      id: el[0],
      key: el[0],
      data: el[1],
      showMenu: el[0].includes("-m") ? true : false,
      showArrow: el[0].includes("-b") ? true : false,
    };
    if (el[0].includes("-h")) return <ContainerRow {...keys} />;
    if (el[0].includes("-s")) return <ContainerSlideShow {...keys} />;
    if (el[0].includes("-l")) return <Contact/>

    if (el[0].includes("-t"))
      return <ContainerColumn {...keys} content="text" />;

    return <ContainerColumn {...keys} content="image" />;
  };

  return (
    <AppContextProvider value={settings}>
      <Navigation><Menu>{data.map((el) => componentSelector(el))}</Menu></Navigation>
    </AppContextProvider>
  );
}

export default Controller;
