import React, { useEffect, useState } from "react";
import { useAppContext } from "./context.hooks";
import DOWNLOAD_EN from "../content/EN_PLASHKA_download pdf.svg";
import DOWNLOAD_RU from "../content/RU_PLASHKA_скачать пдф.svg";
import WRITE_EN from "../content/EN_PLASHKA_write me.svg";
import WRITE_RU from "../content/RU_PLASHKA_написать.svg";
import CALL_RU from "../content/RU_PLASHKA_позвонить.svg";
import CALL_EN from "../content/EN_PLASHKA_call me.svg";
import TWITTER from "../content/PLASHKA_твиттер.svg";
import FACEBOOK from "../content/PLASHKA_ФБ.svg";
import TELEGRAM from "../content/PLASHKA_телега.svg";
import INSTAGRAM from "../content/PLASHKA_инст.svg";
import LANG from "../content/EN.svg"


const Menu = ({ children }) => {
  const { settings, setMenuShow, changeLanguage } = useAppContext();
  const [showClass, setShowClass] = useState("-right-1/2 hidden");
  const btnCall = settings.language === "english" ? CALL_EN : CALL_RU;
  const btnDownload = settings.language === "english" ? DOWNLOAD_EN : DOWNLOAD_RU;
  const btnWrite = settings.language === "english" ? WRITE_EN : WRITE_RU;
  const btnLang = LANG;
  const dLink = settings.language === "russian" ? "https://drive.google.com/file/d/1jWgshQnTOFRVYkxSXNLcMEL9bQekFSMl/view?usp=share_link" : "https://drive.google.com/file/d/16bYPuQ0-OB4sR-EyrMeQNT0nQOxhXEE3/view?usp=sharing"

  useEffect(() => {
    settings.showMenu ? setShowClass("lg:left-2/3 sm:left-1/2 left-1/3") : setShowClass("-right-2/3");
  },[settings.showMenu]);

  return (
    <div className="relevant h-screen w-screen " >
      <div
        className={`fixed lg:top-1/3 top-1/3 lg:w-1/3 sm:w-1/2 w-2/3 lg:h-3/4 h-1/3 transition-all delay-500 duration-1000  z-20 flex align-middle justify-center flex-col  relevant ${showClass}`}
>  
          <div className="mt-4 lg:ml-20 ml-10 z-30  lg:h-8 h-5 "><a href={dLink}><img className="h-full" src={btnDownload} alt=""/></a></div>
          <div className="mt-4 lg:ml-20 ml-10 z-30  lg:h-8 h-5"><a href="tel:+79261673588"><img className="h-full" src={btnCall} alt=""/></a></div>
          <div className="mt-4 lg:ml-20 ml-10 z-30  lg:h-8 h-5"><a href="mailto:volodyakurashov@gmail.com"><img className="h-full" src={btnWrite} alt=""/></a></div>
          <div className="mt-4 lg:ml-20 ml-10 z-30  lg:h-8 h-5" onClick={() => changeLanguage(settings)}><img className="h-full" src={btnLang} alt=""/></div>


          <div className="mt-10 lg:mt-20 lg:h-8 h-5  flex flex-row z-30 w-full justify-center">
          <a href="https://www.facebook.com/volodyakurashov"><img className="h-full mx-1 w-5 sm:w-8" src={FACEBOOK} alt=""/></a>
          <a href="https://www.instagram.com/volodyakurashov"><img className="h-full mx-1 w-5 sm:w-8" src={INSTAGRAM} alt=""/></a>
          <a href="https://twitter.com/VolodyaKurashov"><img className="h-full mx-1 w-5 sm:w-8" src={ TWITTER} alt=""/></a>
          <a href="https://t.me/VolodyaKurashov"><img className="h-full mx-1 w-5 sm:w-8" src={TELEGRAM} alt=""/></a>
          </div>
          <div       
className={`fixed  lg:h-2/3 sm:w-1/2 lg:w-1/3 w-2/3 h-2/4 z-20 bg-white opacity-60 transition-all delay-500 duration-1000 ${showClass}`} onClick={() => setMenuShow(settings)}></div>
      </div>
      {children}
    </div>
  );
};

export default Menu;
