import React, { useEffect, useState } from "react";
import Logo from "./logo";
import { useAppContext } from "./context.hooks";

const imageCloudinaryBase = "https://res.cloudinary.com/dtunq4piy/image/upload";
const imagePath = "/v1654715228/moscowcycle/landing";

const ImageContent = (props) => {
  const { settings} = useAppContext();
  const [loaded, setLoaded] = useState(false);
  const [viewed, setView] = useState(false);
  const [options, setOptions] = useState();
  const [imageUrl, setPath] = useState();

  const contentPath =
    props.english && settings.language === "english"
      ? props.english
      : props.path;
    useEffect(() => {
    if (settings.width > 2000) {
      setOptions(`/f_auto/dpr_${settings.DPR}.0`);
    } else if (settings.width > 1000) {
      setOptions(`/c_scale,w_1500/f_auto/dpr_${settings.DPR}.0`);
    } else if (settings.width > 600) {
      setOptions(`/c_scale,w_1000/f_auto/dpr_${settings.DPR}.0`);
    } else if (settings.width < 600) {
      setOptions(`/c_scale,w_600/f_auto/dpr_${settings.DPR}.0`);
    }
  });

  useEffect(() => {
    if (props.inView) onView();
  }, [props.inView]);

  useEffect(()=> {
    setPath(imageCloudinaryBase + options + imagePath);
  })


  const onView = () => {
    setView(true);
  };

  const onLoad = () => {
    setLoaded(true);
  };


  return (
    <>
      {" "}
      {props.load === "yes" || viewed ? (
        <img
          data-id = {props.id ? props.id : ""}
          alt=""
          onLoad={onLoad}
          src={imageUrl + contentPath}
          className={
            loaded
              ? `  row-start-1 row-end-2s pointer-events-none w-full`
              : "hidden"
          }
        />
      ) : (
        ""
      )}
      {loaded ? "" : <Logo />}
    </>
  );
};

export default ImageContent;
