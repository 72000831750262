import React, { useEffect, useState } from "react";
import { useAppContext } from "./context.hooks";
import Logo from "./logo";

const imageCloudinaryBase = "https://res.cloudinary.com/dtunq4piy/image/upload";
const imagePath = "/v1654715228/moscowcycle/landing";

const TextContent = (props) => {
  //картинка
  const [loaded, setLoaded] = useState(false);
  const [viewed, setView] = useState(false);

  const { settings } = useAppContext();
  const contentPath =
    settings.width > 960
      ? settings.language === "english"
        ? props.desktop.english
        : props.desktop.russian
      : settings.language === "english"
      ? props.mobile.english
      : props.mobile.russian;

  const imageUrl = imageCloudinaryBase + imagePath;
  //картинка

  useEffect(() => {
    if (props.inView) onView();
  }, [props.inView]);
  //картинка

  const onView = () => {
    setView(true);
  };
  //картинка

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      {" "}
      {props.load === "yes" || viewed ? (
        <img
          alt=""
          data-id = {props.id ? props.id : ""}
          onLoad={onLoad}
          src={imageUrl + contentPath}
          className={
            loaded
              ? `  row-start-1 row-end-2s pointer-events-none w-full`
              : "hidden"
          }
        />
      ) : (
        ""
      )}
      {loaded ? "" : <Logo />}
    </>
  );
};

export default TextContent;
