import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useAppContext } from "./context.hooks";
import ImageContent from "./image.content";
import TextContent from "./text.content";

const ContainerColumn = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  
  const dataId = props.id;
  const [animate, setAnimate] = useState("");
  useEffect(() => {
    inView ? setAnimate("animate-fadeOnce") : setAnimate("");

  }, [inView]);


  return (
    <div
      ref={ref}
      data-id={dataId}
      className={`w-screen flex-col flex snap-always snap-center h-min-screen ${animate} bg-white`}
    >
      <div data-id={props.data[0].id}
      className={`snap-center pointer-events-none flex-none`}>
        {props.data.map((el) => (
            props.content === "image" ? (
              <ImageContent
                inView={inView}
                {...el}
                key={"containerColumnImage" + el.name}
              />
            ) : (
              <TextContent
                inView={inView}
                {...el}
                key={"containerColumnText" + el.name}
              />
            )
        ))}
      </div>
    </div>
  );
};

export default ContainerColumn;

